$(document).foundation();

$(document).ready(function() {

	$(window).scroll(function() {
	  $(".cog").css({
		transform: "rotate(" + window.pageYOffset * 0.6 + "deg)"
	  });
	});

	$(".hz-scroller .jumplink").on("click", function() {
		$(".hz-scroller .jumplink").removeClass("is-active");
		$(this).addClass("is-active");
		
		// CALL scrollCenter PLUSGIN
		$(".hz-scroller").scrollCenter(".is-active", 300);
	  });
	  
	  jQuery.fn.scrollCenter = function(elem, speed) {
	  
		// this = #timepicker
		// elem = .active
	  
		var active = jQuery(this).find(elem); // find the active element
		//var activeWidth = active.width(); // get active width
		var activeWidth = active.width() / 2; // get active width center
	  
		//alert(activeWidth)
	  
		//var pos = jQuery('#timepicker .active').position().left; //get left position of active li
		// var pos = jQuery(elem).position().left; //get left position of active li
		//var pos = jQuery(this).find(elem).position().left; //get left position of active li
		var pos = active.position().left + activeWidth; //get left position of active li + center position
		var elpos = jQuery(this).scrollLeft(); // get current scroll position
		var elW = jQuery(this).width(); //get div width
		//var divwidth = jQuery(elem).width(); //get div width
		pos = pos + elpos - elW / 2; // for center position if you want adjust then change this
	  
		jQuery(this).animate({
		  scrollLeft: pos
		}, speed == undefined ? 1000 : speed);
		return this;
	  };
	  
	  // http://podzic.com/wp-content/plugins/podzic/include/js/podzic.js
	  jQuery.fn.scrollCenterORI = function(elem, speed) {
		jQuery(this).animate({
		  scrollLeft: jQuery(this).scrollLeft() - jQuery(this).offset().left + jQuery(elem).offset().left
		}, speed == undefined ? 1000 : speed);
		return this;
	  };

  });